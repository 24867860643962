import React, { useEffect, useState } from "react";
import Layout from "../../components/layout/Layout";
import spinner from "../../assets/images/spinner.gif";
import { Modal } from "@mui/material";
import {
  PencilAltIcon,
  TrashIcon,
  XCircleIcon,
  XIcon,
} from "@heroicons/react/solid";
import Radio from "../../components/radio/Radio";
import { toast } from "react-hot-toast";

import {
  deleteHolidayForm,
  deleteLeaveManagement,
  postHolidayForm,
  postLeaveManagementForm,
  updateHolidayForm,
} from "../../api/holiday";
import Moment from "react-moment";
import useRead from "../../hooks/useRead";
import { useSearchParams } from "react-router-dom";
import Pagination from "../../components/pagination";
import { useQueryClient } from "@tanstack/react-query";
import useGet from "../../hooks/read/useGet";
import DatePicker from "react-datepicker";
import Cookies from "universal-cookie";
import { ROLE } from "../../config";
import { Input } from "../../components/ui";
import { useForm } from "react-hook-form";
import { checkIfNotEmpty } from "../../utility/utils";

const cookies = new Cookies();

let IS_REQUIRED = true;
function LeaveManagement({ id, year }) {
  const [pageNo, setPageNo] = useState(0);
  console.log(year, "year");
  // First date of the year
  const firstDate = new Date(year.getFullYear(), 0, 1); // January is month 0
  console.log(firstDate, "First date of the year");

  // Last date of the year
  const lastDate = new Date(year.getFullYear(), 11, 31, 23, 59, 59, 999); // December is month 11
  console.log(lastDate, "Last date of the year");
  // Helper function to format dates as DD/MM/YYYY
  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };
  const [role, setRole] = useState("");
  useEffect(() => {
    setRole(cookies.get(ROLE));
  }, []);
  const queryClient = useQueryClient();
  const [searchParams, setSearchParams] = useSearchParams();
  const initialState = {
    holiday_type: "single",
    from_date: "",
    to_date: "",
    title: "",
    employment_type: [],
  };
  /**
   * Work around for the date is converted to year only
   * two states are used for this one foe saving the date object and one for only year will be sent to backend
   */
  let initialHolidayData = {
    limit: 10,
    search_query: searchParams.get("search_query") || "",
    page_no: 0,
    month_year: year?.getFullYear(),
    user_id: id,
  };
  let initialHolidayFilterData = {
    limit: 10,
    search_query: searchParams.get("search_query") || "",
    page_no: 0,
    user_id: id,
    month_year: year?.getFullYear(),
  };
  const [holidayId, setHolidayId] = useState("");
  const [filterData, setFilterData] = useState(initialHolidayFilterData);
  const [isLoading, setIsLoading] = useState(false);
  const [holidayData, setHolidayData] = useState(initialState);
  const [holidayModal, setHolidayModal] = useState(false);
  const [isAddingData, setAddingData] = useState(false);
  const [deleteConfirmationModal, setDeleteConfirmationModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [openHolidayEditModal, setOpenHolidayEditModal] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const {
    list: holidayList,
    paramsObject,
    setGetListParams,
  } = useGet({
    url: "getUserLeaveManagementLsiting",
    method: "get",
    initialData: initialHolidayData,
  });

  const {
    list: userdata,
    paramsObject: userParams,
    setGetListParams: setUserParams,
  } = useGet({
    url: "getUserData",
    method: "get",
    initialData: {
      user_id: id,
      ...initialHolidayData,
    },
  });
  console.log(userdata?.data?.data);

  const handleHolidayFormInputs = (e) => {
    let value = e.target.value;
    let data = {
      ...holidayData,
      [e.target.name]: value,
    };
    setHolidayData(data);
  };

  // const { list: employmentList } = useRead({
  //   url: "employment_listing",
  //   initialData: {
  //     limit: 50,
  //     search_query: "",
  //   },
  // });

  const handleSubmitFormData = (event) => {
    event.preventDefault();

    // Extract the values for annual and available leaves
    const annual = getValues("leaves.annual");
    const available = getValues("leaves.available");

    // Validate the values
    if (!annual || !available) {
      console.error("Annual or Available Leaves are missing");
      return;
    }

    // Create the payload for the API
    const payload = {
      leaves: JSON.stringify([{ annual: annual, available: available }]), // Convert to string as required
      user_id: id,
    };

    // Call the API
    postLeaveManagementForm(payload)
      .then((response) => {
        alert(response.data.message);
        console.log("Form submitted successfully", response);
        toast.success("Leave management data submitted successfully!");
        // Close the correct modal:
        setHolidayModal(false);
        reset();
        queryClient.invalidateQueries(["getUserLeaveManagementLsiting"]);

        // Reset the loading state
        setAddingData(false);
      })
      .catch((error) => {
        alert("Error submitting form");
        console.error("Error submitting form", error);
        toast.error("Failed to submit leave management data.");
      });
  };

  const handleCancelForm = () => {
    setHolidayModal(false);
    setHolidayData(initialState);
    setOpenHolidayEditModal(false);
  };

  const handleOpenDeleteModal = (id) => {
    setHolidayId(id);
    setDeleteConfirmationModal(true);
  };
  const handleDeleteLeave = () => {
    setIsDeleting(true);
    deleteLeaveManagement(holidayId)
      .then((res) => {
        if (res.status === 200) {
          setDeleteConfirmationModal(false);
          // handleFetchHoliday()
          alert("Deleted Successfully");
          queryClient.invalidateQueries("holidaylist");
          setIsDeleting(false);
        }
      })
      .catch((err) => {
        alert("Something went wrong!");
        setIsDeleting(false);
      });
  };

  const handleGetSingleLeaveData = (id) => {
    setHolidayData(holidayList.data.data?.find((data) => data.id === id));
    setOpenHolidayEditModal(true);
  };

  const handleEditHoliday = (e) => {
    setIsUpdating(true);
    e.preventDefault();
    updateHolidayForm(holidayData)
      .then((res) => {
        if (res.status === 200) {
          // handleFetchHoliday()
          queryClient.invalidateQueries("holidaylist");
          setOpenHolidayEditModal(false);
          setHolidayData(initialState);
          setIsUpdating(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsUpdating(false);
      });
  };

  const handleCheckValue = (e, item) => {
    let checked = e.target.checked;
    if (checked) {
      setHolidayData((prev) => {
        return {
          ...prev,
          employment_type: [...holidayData.employment_type, item.id],
        };
      });
    } else {
      let checkedId = holidayData.employment_type.filter((x) => x !== item.id);
      setHolidayData((prev) => {
        return {
          ...prev,
          employment_type: checkedId,
        };
      });
    }
  };

  const handlePageClick = (val) => {
    let data = {
      ...paramsObject,
      page_no: val.selected,
    };
    setPageNo(val.selected);
    setGetListParams(data);
    setSearchParams(data);
  };

  /**
   * Work around for the date is converted to year only
   * two states are used for this one foe saving the date object and one for only year will be sent to backend
   */
  const handleSetYear = (value) => {
    let data = {
      ...paramsObject,
      month_year: new Date(value).getFullYear(),
    };
    let dataForSearchParams = {
      ...paramsObject,
      month_year: value,
    };
    setGetListParams(data);
    setSearchParams(dataForSearchParams);
    setFilterData(dataForSearchParams);
  };
  const onLeavesChanged = () => {
    const annualLeaves = getValues("leaves.annual");
    const dateOfJoiningRaw = userdata?.data?.data.date_of_joining;

    console.log("Raw Date of Joining:", dateOfJoiningRaw);

    // Convert seconds to milliseconds and parse the date
    let parsedDateOfJoining = dateOfJoiningRaw
      ? new Date(dateOfJoiningRaw * 1000) // Multiply by 1000
      : null;

    // Validate parsed date
    if (!parsedDateOfJoining || isNaN(parsedDateOfJoining)) {
      console.error("Invalid Date of Joining:", dateOfJoiningRaw);
      return; // Exit the function if the date is invalid
    }

    // Get the first date of the current year
    const firstDate = new Date(new Date().getFullYear(), 0, 1);

    // Validate annual leaves
    if (!annualLeaves || isNaN(annualLeaves)) {
      console.error("Invalid Annual Leaves:", annualLeaves);
      return; // Exit the function if annual leaves are invalid
    }

    // Perform the pro-rata leave calculation
    if (parsedDateOfJoining.getFullYear() !== firstDate.getFullYear()) {
      setValue("leaves.available", annualLeaves);
    } else {
      const leavesInOneMonth = annualLeaves / 12;
      const monthsDifference =
        12 - (parsedDateOfJoining.getMonth() - firstDate.getMonth());
      const totalLeaves = Math.floor(leavesInOneMonth * monthsDifference);

      setValue("leaves.available", totalLeaves);
    }
  };

  const {
    getValues,
    control,
    setValue,
    reset,
    formState,
    register,
    handleSubmit,
    watch,
  } = useForm();
  return (
    <>
      <div className="flex items-end justify-between px-4">
        <button
          className=" bg-[color:var(--color1)] items-end text-white rounded px-4 py-2 text-lg"
          onClick={() => setHolidayModal(true)}
        >
          + Add Leave Management
        </button>
        {/* <div className=" lg:basis-[25%]">
          <label className=" roboto p-2 label">Select Year</label>
          
          <DatePicker
            name="month_year"
            autoComplete="false"
            className="input"
            selected={filterData.month_year}
            showYearPicker
            dateFormat="yyyy"
            onChange={handleSetYear}
          />
        </div> */}
      </div>
      {isLoading ? (
        <div className=" w-16 h-16 mx-auto mt-8">
          <img src={spinner} alt="spinner" className="w-full h-full" />
        </div>
      ) : (
        <div class="max-w-full overflow-x-auto px-4 mt-8">
          {holidayList.data?.data?.length <= 0 ? (
            <div className="text-center">
              <p className=" text-gray-700 text-3xl">No Data Found</p>
            </div>
          ) : (
            <>
              <table class="table-auto w-full">
                <thead>
                  <tr class=" bg-[color:var(--color1)] text-center">
                    <th class="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4 border-l border-transparent hidden lg:table-cell">
                      Sr.No
                    </th>
                    <th class="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">
                      From Date
                    </th>
                    <th class="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">
                      To Date
                    </th>
                    <th class="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">
                      Annual
                    </th>
                    <th class="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">
                      Remaining
                    </th>
                    <th class="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">
                      Status
                    </th>
                    <th class="w-1/6 min-w-[160px] text-lg  font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">
                      Action
                    </th>
                    {/* <th class="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">
                      From - To Date
                    </th>
                    {role === "employee" || role === "consultant" ? null : (
                      <>
                        <th class="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">
                          Applicable To
                        </th>
                      </>
                    )} */}
                  </tr>
                </thead>
                <tbody>
                  {holidayList.data?.data?.map((data, idx) => {
                    const {
                      id,
                      title,
                      from_date,
                      to_date,
                      annual,
                      by_default,
                      remaining,
                      utilized_type,
                      utilized_date,
                      applicable,
                      status,
                    } = data;
                    return (
                      <tr key={idx}>
                        <td class="text-center text-dark font-medium text-sm lg:text-base py-2 lg:py-3 px-2 bg-[#F3F6FF] border-b border-[#E8E8E8] hidden lg:table-cell">
                          {pageNo * initialHolidayData?.limit + idx + 1}
                        </td>
                        <td class="text-center text-dark font-medium text-sm lg:text-base py-2 lg:py-3 px-2 bg-[#F3F6FF] border-b border-[#E8E8E8]">
                          <Moment format="DD/MM/YYYY">
                            {from_date * 1000}
                          </Moment>
                        </td>
                        <td class="text-center text-dark font-medium text-sm lg:text-base py-2 lg:py-3 px-2 bg-[#F3F6FF] border-b border-[#E8E8E8]">
                          <Moment format="DD/MM/YYYY">{to_date * 1000}</Moment>
                        </td>
                        <td class="text-center text-dark font-medium text-sm lg:text-base py-2 lg:py-3 px-2 bg-[#F3F6FF] border-b border-[#E8E8E8]">
                          {annual}
                        </td>
                        <td class="text-center text-dark font-medium text-sm lg:text-base py-2 lg:py-3 px-2 bg-[#F3F6FF] border-b border-[#E8E8E8]">
                          {remaining}
                        </td>
                        <td
                          className={`text-center font-medium text-sm lg:text-base py-2 lg:py-3 px-2 bg-[#F3F6FF] border-b border-[#E8E8E8] ${
                            by_default === "Active"
                              ? "text-green-500"
                              : "text-red-500"
                          }`}
                        >
                          {by_default}
                        </td>
                        <td class="text-center text-dark font-medium justify-center items-center flex text-sm lg:text-base py-2 lg:py-3 px-2 bg-[#F3F6FF] border-b border-[#E8E8E8]">
                          <TrashIcon
                            className=" text-gray-600 w-6 hover:text-orange-600 cursor-pointer"
                            onClick={() => handleOpenDeleteModal(id)}
                          />
                        </td>
                        {/* <td class="text-center text-dark font-medium text-sm lg:text-base py-2 lg:py-3 px-2 bg-[#F3F6FF] border-b border-[#E8E8E8]">
                          <Moment format="ll">{from_date}</Moment>
                          {holiday_type === "multiple" && (
                            <>
                              {" "}
                              - <Moment format="ll">{to_date}</Moment>
                            </>
                          )}
                        </td>
                        {role === "employee" || role === "consultant" ? null : (
                          <>
                            <td class="text-center text-dark font-medium text-sm lg:text-base py-2 lg:py-3 px-2 bg-[#F3F6FF] border-b border-[#E8E8E8] capitalize">
                              {applicable?.toLowerCase()}
                            </td>
                          </>
                        )} */}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <div className="px-5 mt-5">
                <Pagination
                  currentPage={+paramsObject.page_no}
                  lengthofItems={holidayList.data?.total_count}
                  limit={paramsObject.limit}
                  onPageChange={handlePageClick}
                />
              </div>
            </>
          )}
        </div>
      )}
      <Modal open={holidayModal} onClose={handleCancelForm}>
        <div className="bg-white p-6 rounded shadow-lg w-[90%] max-w-2xl mx-auto mt-16">
          <h2 className="text-2xl font-semibold mb-4">Add Leave Management</h2>
          <form
            className="grid gap-5 grid-cols-2"
            onSubmit={handleSubmitFormData}
          >
            <div className="flex flex-col">
              <p className="block font-medium">First Date of the Year</p>
              <p className="block "> {formatDate(firstDate)}</p>
            </div>
            <div className="flex flex-col">
              <p className="block font-medium">Last Date of the Year</p>
              <p className="block "> {formatDate(lastDate)}</p>
            </div>

            <div className="mb-4">
              <label className="block font-medium">Date Of Joining</label>
              <input
                disabled
                type="text"
                name="date_of_joining"
                value={
                  userdata?.data?.data.date_of_joining
                    ? new Date(
                        userdata.data.data.date_of_joining * 1000
                      ).toLocaleDateString("en-GB")
                    : "N/A"
                }
                onChange={handleHolidayFormInputs}
                className="w-full p-2 border rounded"
                required
              />
            </div>
            <div className="mb-4">
              <label className="block font-medium">Probabation Date</label>
              <input
                disabled
                type="text"
                name="date_of_probabtion"
                value={
                  userdata?.data?.data.date_of_probation
                    ? new Date(
                        userdata.data.data.date_of_probation * 1000
                      ).toLocaleDateString("en-GB")
                    : "N/A"
                }
                onChange={handleHolidayFormInputs}
                className="w-full p-2 border rounded"
                required
              />
            </div>
            <div className="mb-4">
              <Input
                control={control}
                label={"Annual Leaves"}
                name={"leaves.annual"}
                placeholder={"Please enter annual leaves"}
                onChangeFn={onLeavesChanged}
                rules={{
                  required: "This filed is required",
                }}
              />
            </div>
            <div className="mb-4">
              <Input
                control={control}
                label={"Available Leaves"}
                name={"leaves.available"}
                placeholder={"Please enter available leaves"}
                rules={{
                  required: "This field is required",
                }}
              />
            </div>

            <div className="flex col-span-2 justify-end space-x-4">
              <button
                type="button"
                onClick={handleCancelForm}
                className="py-2 px-4 border border-gray-400 rounded"
              >
                Cancel
              </button>
              <button
                type="submit"
                disabled={isAddingData}
                className={`py-2 px-4 text-white rounded ${
                  isAddingData ? "bg-gray-400" : "bg-primary"
                }`}
              >
                {isAddingData ? "Adding..." : "Add Leave Management"}
              </button>
            </div>
          </form>
        </div>
      </Modal>
      <Modal open={deleteConfirmationModal}>
        <div className="w-[60%] bg-white absolute left-1/2 -translate-x-1/2 top-[20%]">
          <div className="document-wrapper px-4 divide-y">
            <div className="document-head py-4">
              <div className="flex items-center justify-between">
                <h3 className=" text-gray-700 text-3xl font-semibold">
                  Delete Leave{" "}
                </h3>
                <div
                  className=" w-6 h-6 cursor-pointer"
                  onClick={() => setDeleteConfirmationModal(false)}
                >
                  <XIcon className=" text-black w-full h-full" />
                </div>
              </div>
            </div>
            <div className="document-body max-h-96 overflow-auto py-4">
              <div>
                <p className=" roboto text-center font-bold text-2xl text-gray-700 mb-4">
                  Are you sure you want to delete this Field?
                </p>
                {isDeleting ? (
                  <div className=" w-8 h-8 mx-auto">
                    <img
                      src={spinner}
                      alt="spinner"
                      className="w-full h-full"
                    />
                  </div>
                ) : (
                  <div className="space-x-3 text-center ">
                    <button
                      className="px-4 py-2 rounded-md font-semibold bg-red-200 text-red-700 border-red-800"
                      onClick={handleDeleteLeave}
                    >
                      Yes
                    </button>
                    <button
                      className="px-4 py-2 rounded-md font-semibold  border"
                      onClick={() => setDeleteConfirmationModal(false)}
                    >
                      Cancel
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default LeaveManagement;
